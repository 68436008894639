'use client';

import { useSearchParams } from 'next/navigation';
import { FunctionComponent, PropsWithChildren, useMemo } from 'react';
import ButtonLink, { ButtonLinkProps } from '../ButtonLink';

export type EducatorSignUpButtonLinkProps = PropsWithChildren<Omit<ButtonLinkProps, 'href'>>;

const EDUCATOR_SIGN_UP_URL = process.env.NEXT_PUBLIC_EDUCATOR_SIGN_UP_URL;

const EducatorSignUpButtonLink: FunctionComponent<EducatorSignUpButtonLinkProps> = (props) => {
    const searchParams = useSearchParams();
    const referredBy = useMemo(() => searchParams?.get('rfrl'), [searchParams]);

    if (!EDUCATOR_SIGN_UP_URL) {
        throw new Error('Missing educator sign up url');
    }

    const signUpLinkWithReferal = useMemo<string>(() => {
        if (referredBy) {
            return `${EDUCATOR_SIGN_UP_URL}?rfrl=${referredBy}`;
        }

        return EDUCATOR_SIGN_UP_URL;
    }, [referredBy]);

    return (
        <ButtonLink {...props} href={signUpLinkWithReferal} rel="nofollow">
            {props.children || 'Start for Free'}
        </ButtonLink>
    );
};

export default EducatorSignUpButtonLink;
